import React from "react"
import { Input } from "@chakra-ui/input"
import { Box } from "@chakra-ui/layout"

export interface VideoInputProps {
  setFile: any
  position?: string
  top?: string
  left?: string
}

const VideoInput: React.FC<VideoInputProps> = props => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files || e.target.files.length === 0) {
      return
    }
    props.setFile(e.target.files[0])
  }

  return (
    // TODO video recorder for android web and desktop webcam users
    // @ts-ignore
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      {...props}
    >
      <Input
        width="0.1px"
        height="0.1px"
        opacity={0}
        overflow="hidden"
        position="relative"
        // zIndex={-1}
        id="video-file"
        type="file"
        accept="video/*"
        capture="user"
        onChange={handleChange}
      />
      <label
        htmlFor="video-file"
        onClick={undefined}
        style={{
          cursor: "pointer",
          position: "relative",
          top: "100%",
        }}
      >
        {/* children must be zIndex < 1 */}
        {props.children}
      </label>
    </Box>
  )
}

export default VideoInput
