import { Container } from "@chakra-ui/layout"
import React from "react"
import VideoInput from "../video_capture/video_input"
import ZiptiButton from "../zipti_button"
import ZiptiText from "../zipti_text"

export interface VideoPromptModalProps {
  promptText: string
  buttonText: string
  handleVideoFile: any
}

const VideoPromptModal: React.FC<VideoPromptModalProps> = (
  props: VideoPromptModalProps
) => {
  return (
    <Container centerContent width="80%" position="relative">
      <ZiptiText position="relative" top="5vh">
        {props.promptText}
      </ZiptiText>
      <VideoInput setFile={props.handleVideoFile}>
        <ZiptiButton mt={{ base: "0.25rem", md: "2rem" }} zIndex="-1">
          {props.buttonText}
        </ZiptiButton>
      </VideoInput>
    </Container>
  )
}

export default VideoPromptModal
